<template>
  <div id="IptPro">
    <el-row>
    <el-col>
        <div class="bgimg"><img src="http://cdn.valuetech.com.cn/images/%E5%A4%B4%E5%9B%BE/%E8%87%AA%E4%B8%BB%E4%BA%A7%E5%93%81.jpg"></div>
    </el-col>
    </el-row>
    <el-row type="flex" justify="center"  class="tabs">
      <el-col>
        <tab-page :cmsgList="$t('productMsg')" :showTwoBtn="true" ></tab-page>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import TabPage from "./c-components/TabPage.vue";
export default {
  name: "IndependentProduct",
  components: {
    TabPage,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@media screen and(max-width:1200px) {
  #IptPro {
    min-height: 700px;
  }
  .bgimg {
    margin-top: 50px !important;
    height: 170px;
    img {
      object-fit: fill;
    }
  }
}
</style>
